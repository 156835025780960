exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-confirm-form-tsx": () => import("./../../../src/templates/confirm-form.tsx" /* webpackChunkName: "component---src-templates-confirm-form-tsx" */),
  "component---src-templates-dokumentensuche-tsx": () => import("./../../../src/templates/dokumentensuche.tsx" /* webpackChunkName: "component---src-templates-dokumentensuche-tsx" */),
  "component---src-templates-product-finder-tsx": () => import("./../../../src/templates/product-finder.tsx" /* webpackChunkName: "component---src-templates-product-finder-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-search-results-tsx": () => import("./../../../src/templates/search-results.tsx" /* webpackChunkName: "component---src-templates-search-results-tsx" */),
  "component---src-templates-standard-tsx": () => import("./../../../src/templates/standard.tsx" /* webpackChunkName: "component---src-templates-standard-tsx" */)
}

