import "bootstrap/dist/css/bootstrap.min.css";
import "components/index.css";
import "./src/index.css";
import "./src/print.css";

export const onInitialClientRender = () => {
  const CookieConsentCallback = (consent: any) => {
    if (consent.statistics) {
      const loadGTM = (w, d, s, l, i) => {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);

        //noscript
        const ns = d.createElement("noscript");
        ns.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${i}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        d.body.appendChild(ns);
      };
      loadGTM(window, document, "script", "dataLayer", "GTM-WRP38VW");
    }
  };

  window.addEventListener(
    "CookiebotOnAccept",
    function (e) {
      CookieConsentCallback(e.detail || e.target.Cookiebot.consent);
    },
    false
  );

  if (window.CookieConsent && window.CookieConsent.consent) {
    CookieConsentCallback(window.CookieConsent.consent);
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location) {
    const hash = location.hash;
    if (hash && hash.startsWith("#job=")) {
      window.scrollTo({ behavior: "instant", top: 0 });
      return;
    }
  }
  if (prevLocation) {
    const prevHash = prevLocation.hash;

    if (
      prevHash &&
      prevHash.startsWith("#job=") &&
      // if we travell from ".../stellenanzeigen/#job=..." to ".../stellenanzeigen" we want to reload the page to allow the job board to be loaded again
      location.pathname === prevLocation.pathname.split("/#")[0]
    ) {
      window.location.reload();
    }
  }
};

export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.hash) {
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 0);
    }).then(() => {
      const id = routerProps.location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    });
    return true;
  } else {
    window.scrollTo({ behavior: "instant", top: 0 });

    return true;
  }
};
